import { css } from "lit";
import baseStyles from "../shared/styles/base.styles";
import formControlStyles from "../shared/styles/form-control.styles";
export default [baseStyles, formControlStyles, css`
    /** Base */
    :host {
      --_bi-checkbox-size: var(--bi-size-space-m);
      --_bi-checkbox-control-color: var(--bi-color-border-default);
      --_bi-checkbox-box-shadow: none;
      display: inline-grid;
      grid-template-columns: auto 1fr;
      gap: var(--bi-size-space-s);
    }

    /** Checked or Indeterminate */
    :host([checked]),
    :host([indeterminate]) {
      --_bi-checkbox-control-color: var(--bi-color-primary-shade);
    }

    /** Focus */
    .bi-checkbox-control:focus-visible {
      --_bi-checkbox-box-shadow: 0 0 0 1px var(--bi-color-background),
        0 0 0 calc(var(--bi-size-border-weight) + 1px) var(--bi-color-outline);
    }

    /** Error */
    :host([error]) {
      --_bi-checkbox-control-color: var(--bi-color-error-default);
    }

    /** Checkbox */
    .bi-checkbox {
      position: relative;
      display: flex;
      align-items: center;
    }
    .bi-checkbox.bi-checkbox--hasLabel {
      block-size: calc(var(--bi-size-font-m) * var(--bi-line-height));
    }
    .bi-checkbox-control {
      block-size: var(--_bi-checkbox-size);
      inline-size: var(--_bi-checkbox-size);
      border: var(--bi-size-border-weight) solid
        var(--_bi-checkbox-control-color);
      background-color: var(--bi-color-background);
      border-radius: var(--bi-size-border-radius-s);
      box-shadow: var(--_bi-checkbox-box-shadow);
    }
    .bi-checkbox-control:not(:disabled):active {
      opacity: var(--bi-opacity-active);
    }

    /** Icon */
    .bi-checkbox-icon--checked,
    .bi-checkbox-icon--indeterminate {
      pointer-events: none;
      position: absolute;
      inset-inline-start: 0;
      inline-size: var(--_bi-checkbox-size);
      block-size: var(--_bi-checkbox-size);
      display: none;
      align-items: center;
      justify-content: center;
      color: var(--_bi-checkbox-control-color);
      opacity: var(--_bi-checkbox-icon-opacity);
    }
    .bi-checkbox-control:checked ~ .bi-checkbox-icon--checked,
    .bi-checkbox-control:indeterminate ~ .bi-checkbox-icon--indeterminate {
      display: flex;
    }
    .bi-checkbox-icon-inner {
      inline-size: 10px;
      fill: currentColor;
    }

    /** Content */
    .bi-checkbox-content {
      display: var(--_bi-checkbox-content-display, flex);
      flex-direction: column;
    }
    .bi-checkbox-content-main {
      display: flex;
      align-items: center;
      gap: var(--bi-size-space-xs);
    }
    .bi-checkbox-content .bi-form-control-label {
      font-weight: var(--bi-font-weight-normal);
      font-size: var(--bi-size-font-m);
      padding-inline-start: var(--bi-size-space-s);
      margin-inline-start: calc(var(--bi-size-space-s) * -1);
    }

    /** Custom Slot */
    .bi-checkbox-custom {
      cursor: pointer;
      display: flex;
      flex-direction: column;
    }
    .bi-checkbox-custom.bi-checkbox-custom--empty {
      display: none;
    }

    /** Nested */
    .bi-checkbox-nested {
      display: none;
    }
    :host([show-nested]) .bi-checkbox-nested,
    :host([checked]) .bi-checkbox-nested.bi-checkbox-nested--hasNestedElements {
      display: flex;
      grid-column: 1 / -1;
      grid-row: 2;
      padding-inline-start: calc(
        var(--_bi-checkbox-size) + var(--bi-size-space-s)
      );
    }
    :host([show-nested]) .bi-form-control-label-chevron,
    :host([checked]) .bi-form-control-label-chevron {
      transform: rotate(90deg);
    }
  `];
