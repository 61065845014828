import { css } from "lit";
import baseStyles from "../shared/styles/base.styles";
export default [baseStyles, css`
    :host {
      --_bi-fieldset-label-opacity: 1;
      --_bi-fieldset-direction: var(--bi-fieldset-direction, column);
      --_bi-fieldset-justify-content: var(
        --bi-fieldset-justify-content,
        center
      );
      display: block;
    }

    .bi-fieldset {
      padding: 0;
      margin: 0;
      border: none;
    }

    .bi-fieldset-inner {
      flex-direction: column;
      display: flex;
      gap: var(--bi-size-space-s);
    }

    .bi-fieldset-content {
      display: flex;
      flex-direction: var(--_bi-fieldset-direction);
      gap: var(--bi-fieldset-gap, var(--bi-size-space-s));
      justify-content: var(--_bi-fieldset-justify-content);
    }

    :host([disabled]) {
      --_bi-fieldset-label-opacity: var(--bi-opacity-disabled);
    }

    .bi-fieldset-label {
      font-size: var(--bi-size-font-m);
      font-weight: var(--bi-font-weight-semibold);
      line-height: var(--bi-line-height);
      color: var(--bi-color-text-default);
      opacity: var(--_bi-fieldset-label-opacity);
      padding: 0;
    }

    .bi-fieldset-sublabel {
      color: var(--bi-color-text-bright);
      font-size: var(--bi-size-font-s);
    }

    .bi-fieldset-error {
      font-size: var(--bi-size-font-s);
      color: var(--bi-color-error-default);
    }
  `];
