var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, LitElement } from "lit";
import { customElement, queryAssignedElements } from "lit/decorators.js";
import tabGroupStyles from "./tab-group.styles";
import { Tab } from "../tab/tab.component";
/**
 * Tab groups are used to organize content into separate sections.
 * @slot - The tabbed content. Must be instances of `bi-tab-panel`.
 * @slot tab - The tabs to switch between the tabbed content. Must be instances of `bi-tab`.
 */
let TabGroup = class TabGroup extends LitElement {
  constructor() {
    super(...arguments);
    this.tabGroupId = "bi-tab-group-" + Math.random().toString(36).substring(2, 15);
  }
  render() {
    return html`
      <div
        class="bi-tab-group-list"
        role="tablist"
        @click="${this.handleClick}"
        @selected="${this.selectTabPanel}"
        @keydown=${this.handleKeydown}
      >
        <slot name="tab" @slotchange=${this.setupTabs}></slot>
      </div>
      <div class="bi-tab-group-content">
        <slot @slotchange=${this.setupTabPanels}></slot>
      </div>
    `;
  }
  /** Selects a tab by its index. */
  selectTabByIndex(index) {
    if (!this.tabs[index]) {
      throw new Error(`Tab with index ${index} not found.`);
    }
    this.selectTab(this.tabs[index]);
  }
  setTabSelected() {
    this.tabSelected = this.tabSelected || this.querySelector("bi-tab[selected]") || this.tabs[0];
  }
  setupTabs() {
    this.setTabSelected();
    this.tabs.forEach((tab, index) => {
      tab.setAttribute("id", `${this.tabGroupId}-tab-${index + 1}`);
      tab.setAttribute("aria-controls", `${this.tabGroupId}-panel-${index + 1}`);
    });
  }
  setupTabPanels() {
    this.setTabSelected();
    this.tabPanels.forEach((tabPanel, index) => {
      tabPanel.setAttribute("id", `${this.tabGroupId}-panel-${index + 1}`);
      tabPanel.setAttribute("aria-labelledby", `${this.tabGroupId}-tab-${index + 1}`);
      tabPanel.setAttribute("aria-hidden", `${tabPanel.getAttribute("id") !== this.tabSelected.getAttribute("aria-controls")}`);
    });
  }
  handleClick(event) {
    const target = event.target;
    if (target instanceof Tab && !target.hasAttribute("selected")) {
      this.selectTab(target);
    }
  }
  handleKeydown(e) {
    if (["ArrowLeft", "ArrowRight", "ArrowDown", "ArrowUp"].includes(e.key)) {
      e.preventDefault();
      const direction = getComputedStyle(this).direction;
      const nextKeys = direction === "ltr" ? ["ArrowRight", "ArrowDown"] : ["ArrowLeft", "ArrowUp"];
      let nextTab;
      if (nextKeys.includes(e.key)) {
        nextTab = this.tabs[this.tabs.indexOf(this.tabSelected) + 1];
      } else {
        nextTab = this.tabs[this.tabs.indexOf(this.tabSelected) - 1];
      }
      if (nextTab) {
        this.selectTab(nextTab, true);
      }
    }
  }
  selectTab(tab, focus = false) {
    const tabs = this.querySelectorAll("bi-tab");
    tabs.forEach(tab => tab.removeAttribute("selected"));
    tab.setAttribute("selected", "");
    this.tabSelected = tab;
    if (focus) {
      tab.focus();
    }
    tab.dispatchEvent(new Event("selected", {
      bubbles: true,
      composed: true
    }));
  }
  selectTabPanel(event) {
    event.stopPropagation();
    const selectedPanelId = this.tabSelected.getAttribute("aria-controls");
    this.tabPanels.forEach(tabPanel => {
      tabPanel.setAttribute("aria-hidden", `${tabPanel.id !== selectedPanelId}`);
    });
    setTimeout(() => {
      this.tabSelected.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest"
      });
    });
  }
};
TabGroup.styles = tabGroupStyles;
__decorate([queryAssignedElements({
  selector: "bi-tab",
  slot: "tab"
})], TabGroup.prototype, "tabs", void 0);
__decorate([queryAssignedElements({
  selector: "bi-tab-panel"
})], TabGroup.prototype, "tabPanels", void 0);
TabGroup = __decorate([customElement("bi-tab-group")], TabGroup);
export { TabGroup };
