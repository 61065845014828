export const cleanValue = (inputEl, regex) => {
  const {
    value
  } = inputEl;
  const cursor = inputEl.selectionStart;
  const beforeCursor = value.slice(0, cursor);
  const afterCursor = value.slice(cursor, value.length);
  const filteredBeforeCursor = beforeCursor.replace(regex, "");
  const filteredAfterCursor = afterCursor.replace(regex, "");
  const newValue = filteredBeforeCursor + filteredAfterCursor;
  const newCursor = filteredBeforeCursor.length;
  inputEl.value = newValue;
  inputEl.selectionStart = newCursor;
  inputEl.selectionEnd = newCursor;
  return newValue;
};
