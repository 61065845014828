import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { finalize, Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'

import { BingliAuthService } from '@mybingli/security-service'
import { inject, Injectable } from '@angular/core'
import { PreviewService } from '@shared/services/preview.service'

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  private authService = inject(BingliAuthService)
  private previewService = inject(PreviewService)

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token: string | null = this.previewService.isPreviewMode
      ? this.previewService.previewToken
      : this.authService.getTokens()?.bingli_token

    if (token) {
      request = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + token)
      })
    }

    if (!request.headers.has('Content-Type')) {
      request = request.clone({
        headers: request.headers.set('Content-Type', 'application/json')
      })
    } else if (request.headers.get('Content-Type') === 'multipart/form-data') {
      request = request.clone({
        headers: request.headers.delete('Content-Type')
      })
    }

    request = request.clone({
      headers: request.headers.set('Accept', 'application/json')
    })
    return next.handle(request).pipe(
      finalize(() => this.authService.checkTokenExpiry()),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && this.authService.isAuthenticated()) {
          this.authService.logout({ expired: 'true' })
        }
        return throwError(() => error)
      })
    )
  }
}
